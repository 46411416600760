<template>
  <div id="playlist-view">
    <b-btn
      class="mb-3"
      variant="primary"
      title="Back to Playlists"
      @click="$router.go(-1)">
      <b-icon-arrow-left-short /> Back
    </b-btn>
    <b-row class="mb-3" align-v="center">
      <b-col cols="2">
        <b-skeleton-wrapper :loading="thumbLoading">
          <template #loading>
            <b-skeleton-img class="thumb" aspect="1:1"></b-skeleton-img>
          </template>

          <img
            class="img-fluid"
            :class="playlist.thumbnail_url ? 'thumb' : 'default-thumb'"
            :src="playlist.thumbnail_url"
            title="Playlist Photo"
            width="250"
            height="250"
          />
        </b-skeleton-wrapper>

        <!-- Hidden elem to determine if image is loaded -->
        <img
          class="img-fluid hidden"
          :class="playlist.thumbnail_url ? 'thumb' : 'default-thumb'"
          :src="playlist.thumbnail_url"
          title="Playlist Photo"
          @load="onThumbLoad"
        />
      </b-col>
      <b-col cols="7">
        <p class="mb-1 name">{{ playlist.name }}</p>
        <p class="mb-0 info">
          {{ totalSongs }} Song<span v-if="totalSongs > 1">s</span>
        </p>
        <p class="mb-0 info">Premium: {{ premium }}</p>
        <p class="mb-0 info">Published: {{ published }}</p>
        <b-button
          class="mt-2"
          size="sm"
          variant="success"
          @click="openEditPlaylist">
          Edit Details
        </b-button>
      </b-col>
      <b-col cols="3">
        <div v-if="playlist.banner_url">
          <b-skeleton-wrapper :loading="bannerLoading">
            <template #loading>
              <b-skeleton-img></b-skeleton-img>
            </template>

            <img
              class="img-fluid"
              :src="playlist.banner_url"
              title="Playlist Banner"
              alt="Playlist Banner"
            />
          </b-skeleton-wrapper>
          <img
            class="img-fluid hidden"
            :src="playlist.banner_url"
            title="Playlist Banner"
            alt="Playlist Banner"
            @load="onBannerLoad"
          />
        </div>
      </b-col>
    </b-row>

    <Vuetable
      data-path="songs"
      pagination-path="pagination"
      ref="vuetable"
      track-by="songId"
      :api-url="apiUrl"
      :fields="fields"
      :http-options="httpOptions"
      :css="css.table"
      :per-page="10"
      :append-params="moreParams"
      :query-params="{
        sort    : 'sort',
        page    : 'page',
        perPage : 'perPage'
      }"
      @vuetable:pagination-data="onPaginationData">

      <!-- Change Order -->
      <template
        class="text-center"
        slot="order"
        slot-scope="props">
        <select
          class="form-control"
          v-model="props.rowData.orderNo"
          @change="changeOrder(props.rowData)">
          <option
            v-for="(value, key) in orderOptions"
            :key="key"
            :value="value">
            {{ value }}
          </option>
        </select>
      </template>

      <!-- Preview -->
      <template slot="preview" slot-scope="props">
        <vue-plyr :ref="`plyr-${props.rowData.songId}`">
          <audio :id="props.rowData.songId">
            <source :src="props.rowData.url" type="audio/mp3" />
          </audio>
        </vue-plyr>
      </template>

      <!-- Remove Song -->
      <template
        class="text-center"
        slot="actions"
        slot-scope="props">
        <b-button
          variant="danger"
          @click="removeSong(props.rowData)">
          Remove
        </b-button>
      </template>
    </Vuetable>

    <!-- Pagination -->
    <div class="d-flex align-items-center justify-content-end">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
        :css="css.pagination"
      ></vuetable-pagination>
    </div>

    <br>

    <Search  @song-added="reloadSongList" />

    <!-- Modal -->
    <modals-container />

    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import TableStyle from '../table-bootstrap-css.js';
  const EditPlaylistModal = () => import('./EditPlaylistModal');

  export default {
    data() {
      return {
        loading    : false,
        apiUrl     : process.env.VUE_APP_ROOT_API + 'api/playlist/songs',
        moreParams : {
          plistId : this.$route.params.playlistId,
        },
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [ {
          name       : '__slot:order',
          title      : 'Order',
          titleClass : 'text-center',
          dataClass  : 'text-center',
          sortField  : 'order_no',
        }, {
          name      : 'title',
          title     : 'Title',
          sortField : 'music_title',
          direction : 'desc',
        }, {
          name       : '__slot:preview',
          title      : 'Preview',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'length',
          title      : 'Length',
          sortField  : 'length',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__slot:actions',
          title      : '',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        } ],
        playlist      : {},
        totalSongs    : 0,
        maxOrder      : 0,
        orderOptions  : 0,
        thumbLoading  : true,
        bannerLoading : true,
      }
    },
    computed : {
      premium() {
        return (this.playlist.premium === 1) ? 'Yes' : 'No';
      },
      published() {
        return (this.playlist.published === 1) ? 'Yes' : 'No';
      },
    },
    components : {
      'AppLoaderAdmin'     : () => import('@/components/layout/AppLoaderAdmin'),
      'Vuetable'           : () => import('vuetable-2/src/components/Vuetable'),
      'VuetablePagination' :
        () => import('vuetable-2/src/components/VuetablePagination'),
      'VuetablePaginationInfo' :
        () => import('vuetable-2/src/components/VuetablePaginationInfo'),
      'Search' : () => import('./Search'),
    },
    watch : {
      'playlist.thumbnail_url'(val) {
        if (val)
          this.thumbLoading = true;
      },
      'playlist.banner_url'(val) {
        if (val)
          this.bannerLoading = true;
      },
    },
    methods : {
      
      /**
       * Table Data
       * @param data
       * @return {object}
       */
      transform(data) {
        this.orderOptions = data.result.total;
        this.totalSongs = data.result.total;

        const transformed = {};
        transformed.songs = [];

        transformed.pagination = {
          'total'         : data.result.total,
          'per_page'      : data.result.perPage,
          'current_page'  : data.result.currentPage,
          'last_page'     : data.result.lastPage,
          'next_page_url' : data.result.nextPageUrl,
          'prev_page_url' : data.result.prevPageUrl,
          'from'          : data.result.from,
          'to'            : data.result.to,
        };

        for (let i = 0; i < data.result.data.length; i++) {
          transformed.songs.push({
            id      : data.result.data[i].music_playlist_songs_id,
            orderNo : data.result.data[i].order_no,
            songId  : data.result.data[i].mobile_music_id,
            title   : data.result.data[i].music_title,
            length  : this.formatTime(data.result.data[i].length),
            url     : data.result.data[i].url,
          });

          if (data.result.data[i].order_no > this.maxOrder)
            this.maxOrder = data.result.data[i].order_no + 1;
        }

        // Trigger update audio players
        if (this.totalSongs > 0)
          this.updatePlayer();

        return transformed;
      },

      /**
       * Get Playlist Data
       */
      getPlaylist() {
        this.$http.get('api/playlist', {
          params : {
            plistId : this.$route.params.playlistId,
          },
        }).then(response => {
          this.playlist = response.data.data;
        });
      },

      /**
       * Open Edit Playlist Modal
       */
      openEditPlaylist() {
        this.$modal.show(EditPlaylistModal, {
          playlist : this.playlist,
        }, {
          height   : 'auto',
          adaptive : true,
        }, {
          'update-playlist-info' : () => {
            this.getPlaylist();
          },
        });
      },

      /**
       * Remove Song from Playlist
       * @param data
       */
      removeSong(data) {
        this.$http.delete('api/playlist/song', {
          data : {
            mPlaylistId : this.$route.params.playlistId,
            musicId     : data.songId,
          },
        }).then(() => {
          this.$store.dispatch(
            'playlist/getPlaylistSongIds',
            this.$route.params.playlistId);
          this.reloadSongList();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Removed song successfully!',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Update Paginaton info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$nextTick(() => {
          this.$refs.pagination.setPaginationData(paginationData);
          this.$refs.paginationInfo.setPaginationData(paginationData);
        });
      },

      /**
       * Update Vuetable page
       * @param page
       */
      onChangePage(page) {
        this.$nextTick(() => {
          if (this.$refs.vuetable.currentPage !== page)
            this.$refs.vuetable.changePage(page);
        });
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.loading = true;
      },

      /**
       * Hide Loader
       */
      hideLoader() {
        this.loading = false;
      },

      /**
       * Refresh Song List
       */
      refreshSongList() {
        this.$nextTick(() => {
          this.$refs.vuetable.refresh();
        });
      },

      /**
       * Reload Song List (current page will not reset)
       */
      reloadSongList() {
        this.$nextTick(() => {
          this.$refs.vuetable.reload();
        });
      },

      /**
       * Set Alternative Thumbnail
       * @param event
       */
      setAltThumbnail(event) {
        event.target.src =
          require('../../../assets/images/music-album-placeholder.png');
      },

      /**
       * Format Song Length
       * @param value
       */
      formatTime(value) {
        let result = '';
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value - (hours * 3600)) / 60);
        let seconds = value - (hours * 3600) - (minutes * 60);

        // round seconds & exclude remainder
        seconds = parseInt(Math.round(seconds * 100) / 100);

        if (hours > 0) {
          result = (hours < 10 ? '0' + hours : hours) +
            ':' + (minutes < 10 ? '0' + minutes : minutes) +
            ':' + (seconds < 10 ? '0' + seconds : seconds);
        } else {
          result = (minutes < 10 ? '0' + minutes : minutes) + ':' +
            (seconds < 10 ? '0' + seconds : seconds);
        }

        return result;
      },

      /**
       * Change Song Order
       * @param data
       */
      changeOrder(data) {
        this.showLoader();
        this.$http.post('api/playlist/song/order', {
          orderNo      : data.orderNo,
          mPlistSongId : data.id,
          musicId      : data.songId,
          mPlaylistId  : this.$route.params.playlistId,
          
        }).then(() => {
          this.reloadSongList();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Updated song order successfully.',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.hideLoader();
        });
      },

      /**
       * On Thumbnail Load
       */
      onThumbLoad() {
        this.thumbLoading = false;
      },

      /**
       * On Banner Load
       */
      onBannerLoad() {
        this.bannerLoading = false;
      },

      /**
       * Update audio player source
       */
      updatePlayer() {
        const audios = document.getElementsByTagName('audio');
        if (audios.length > 0) {
          for (let i = 0; i < audios.length; i++)
            audios[i].load();
        }
      },
    },
    mounted() {
      // Scroll to top
      window.scrollTo(0, 0);

      if (this.$route.params.playlistId)
        this.getPlaylist();

      this.$store.dispatch('playlist/getRecentSongs');

      this.$store.dispatch(
        'playlist/getPlaylistSongIds',
        this.$route.params.playlistId);

      /**
       * Audio Player Listener
       */
      document.addEventListener('playing', e => {
        const playingAudio = e.target.getElementsByTagName('audio')[0];
        const audios = document.getElementsByTagName('audio');

        if (audios.length > 0) {
          for (let i = 0; i < audios.length; i++) {
            if (audios[i] !== playingAudio)
              audios[i].pause();
          }
        }
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";

  .name {
    font-weight: bold;
  }

  .info {
    font-size: 0.9em !important;
  }

  .thumb, .default-thumb {
    border: 1px solid #dede;
  }

  .default-thumb {
    background-color: rgba(0,0,0,0.3);
  }

  .hidden {
    visibility: hidden;
    position: fixed; //absolute;
    display: inline-block;
  }
</style>